<template>
  <ui-component-modal
    modalTitle="Edit note"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveNote"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <span>Note</span>
        <input
          type="text"
          ref="subject"
          v-model="mNote.Subject"
          @keyup.enter="setBodyFocus"
          class="input"
        />
      </div>

      <div>
        <textarea
          rows="10"
          v-model="mNote.Body"
          class="textarea"
          placeholder="Message"
          ref="body"
          @keyup.ctrl.enter="saveNote"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import noteProvider from '@/providers/note'

export default {
  components: {},

  props: {
    note: {
      type: Object,
      default: null
    },

    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mNote: JSON.parse(JSON.stringify(this.note))
    }
  },

  computed: {},

  created() {},

  mounted() {
    this.$refs.subject.focus()
  },

  methods: {
    setBodyFocus() {
      this.$refs.body.focus()
    },

    saveNote() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        noteProvider.methods
          .updateNote(self.mNote)
          .then(response => {
            if (response.status === 200) {
              self.$emit('noteEdited', response.data)

              self.isSavingSuccess = true

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch(error => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    }
  }
}
</script>
